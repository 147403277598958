<template>
  <Breadcrumb />
  <div class="d-flex justify-content-between new-block">
    <h2></h2>
    <router-link
      :to="{
        name: 'SystemMessageTemplateNew',
        params: { customObjectId: this.$route.params.customObjectId },
      }"
      class="btn btn-success btn-action-new-page"
    >
      <i class="bi bi-plus"></i>
      {{
        $t(
          "SystemMessageTemplates.NewButton",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </router-link>
  </div>
  <Grid :settings="gridSettings" :columns="gridColumns" />
</template>
<script>
export default {
  name: "SystemMessageTemplateList",
  data() {
    return {
      gridSettings: {
        action: "SystemMessageTemplateList",
        requestUrl: String.format("/Lcdp-SystemMessageTemplateList"),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "systemMessageTemplateId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        serialNoLink: `#/SuperAdmin/SystemMessageTemplates/Edit/systemMessageTemplateId=`,

        allowSearchPanel: false,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: true,
        deleteActionUrl: "/Lcdp-SystemMessageTemplateDelete",
        autoOrderProcess: true,

        isGatewayRequest: true,

        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "SystemMessageTemplateEdit",
              params: {
                systemMessageTemplateId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "MessageTemplates.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "SystemMessageTemplates.TemplateKey",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "templateKey",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "SystemMessageTemplates.TemplateLanguage",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "templateLanguage",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "MessageTemplates.Description",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "description",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
    };
  },
  methods: {},
  mounted() {},
};
</script>
